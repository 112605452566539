import { FCWithChildren } from '../../../types/FCWithChildren';

const QuestionTitle: FCWithChildren<unknown> = (props) => {
  const { children } = props;
  return (
    <div className="question-title" data-cy="question">
      {children}
    </div>
  );
};

export default QuestionTitle;
