import { ElementRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { FormRendererMode } from '../../../../contexts/FormRendererDesignContextTypes';
import { FormAction } from '../../../../models/Form';
import { nextTick } from '../../../../utils/ReactUtils';
import { ChevronIcon, ChevronType } from '../../../shared/icon/ChevronIcon';
import FormRendererV2 from '../../renderer/FormRendererV2';
import ActionBaseProps from '../ActionBaseProps';
import QuestionTitle from '../QuestionTitle';
import { mouseAndKeyboardCallbackProps } from '../../../../utils/ComponentUtils';

export type ViewActionData = {
  viewActions?: string[];
};

const ViewAction: FC<ActionBaseProps<string, ViewActionData>> = (props) => {
  const { data } = props;
  const { question, description, previewQuestion, previewDescription, viewActions } = data;
  const {
    mode,
    clientForm,
    containingModuleId,
    containingModuleSectionId,
    actionLookup,
    questionAnswers,
    placeholders,
    visibleActions,
    requiredActions,
    config,
  } = useFormRendererInfo();

  const previewMode = useMemo(() => mode !== FormRendererMode.EditView, [mode]);

  const [expanded, setExpanded] = useState(false);
  const childFormRendererRef = useRef<ElementRef<typeof FormRendererV2>>(null);

  const actionFilter = useCallback(
    (action: FormAction) => {
      return viewActions?.includes(action.id) ?? false;
    },
    [viewActions],
  );

  useEffect(() => {
    if (expanded) {
      nextTick(() => {
        childFormRendererRef.current?.pushState(actionLookup, questionAnswers, placeholders, visibleActions, requiredActions);
      });
    }
  }, [actionLookup, expanded, placeholders, questionAnswers, requiredActions, visibleActions]);

  return (
    <div
      className="border-grey-5 flex flex-col rounded border-2"
      aria-expanded={expanded}
      {...mouseAndKeyboardCallbackProps(() => setExpanded((prev) => !prev))}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div className="flex cursor-pointer items-center justify-between px-4 py-3" data-cy="view-action">
        <div className="text-dpm-14">
          <QuestionTitle>{previewMode ? previewQuestion || question : question}</QuestionTitle>
          <div className="text-color-3 text-dpm-12">{previewMode ? previewDescription || description : description}</div>
        </div>
        <ChevronIcon type={expanded ? ChevronType.UP : ChevronType.DOWN} className="h-6 w-6 justify-end" />
      </div>

      {expanded && (
        <div
          className="border-grey-5 flex flex-col border-t-2 px-4 py-3"
          {...mouseAndKeyboardCallbackProps((e) => {
            e.stopPropagation();
          })}
        >
          <FormRendererV2
            ref={childFormRendererRef}
            clientForm={clientForm}
            form={config}
            initialMode={FormRendererMode.PreviewView}
            canEdit={false}
            moduleId={containingModuleId}
            moduleSectionId={containingModuleSectionId}
            actionFilter={actionFilter}
            renderAllSteps
            featureToggles={{
              disableSectionHeading: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ViewAction;
